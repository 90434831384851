import axios from "axios";
import ziggyRoute from "ziggy-js";
import { useAuthStore } from "~/store/auth";

export default defineNuxtPlugin(async ({ $config }) => {
  const baseUrl = $config.public.baseUrl;
  const authStore = useAuthStore();
  const router = useRouter();
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  let Ziggy = null;

  await axiosInstance.get("https://api.funargos.fr/api/config/routes").then((response) => {
    Ziggy = response.data;
  });

  function route(name, params, absolute) {
    return ziggyRoute(name, params, absolute, Ziggy);
  }

  function call(method, uri, body = {}, query, forceResponseReturn = false) {
    if (method.toLowerCase() === "get") {
      // Pour GET, on passe simplement les params
      return axiosInstance[method](uri, {
        params: query,
      }).then((res) => {
        if (forceResponseReturn) {
          return res;
        }

        if (res.data.data) {
          return res.data.data;
        }
        return res.data;
      });
    } else {
      // Pour les autres types de requêtes (POST, PUT, etc.), on passe les données
      // dans le paramètre "data" et les params comme troisième argument
      return axiosInstance[method](uri, body, {
        params: query,
      }).then((res) => {
        if (forceResponseReturn) {
          return res;
        }

        if (res.data.data) {
          return res.data.data;
        }
        return res.data;
      });
    }
  }

  function setBearerToken(token) {
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"];
    }
  }

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config && error.response && error.response.status === 401) {
        const originalRequest = error.config;
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          authStore
            .refresh()
            .then((res) => {
              const newToken = res.access_token;
              localStorage.setItem("token", newToken);
              if (newToken) {
                setBearerToken(newToken);
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                processQueue(null, newToken);
                resolve(axiosInstance(originalRequest));
              } else {
                reject(err);
              }
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(error);
    }
  );
  return {
    provide: {
      api: {
        client: axiosInstance,
        setBearerToken,
        route,
        call,
      },
    },
  };
});
function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
window.addEventListener('load', () => {
  if (isIOS()) {
    const installButtonContainer = document.getElementById('install-button-container');
    const iosInstallPopup = document.getElementById('ios-install-popup');
    installButtonContainer.style.display = 'block';

 installButton.addEventListener('click', () => {
  iosInstallPopup.style.display = 'flex';
 });
   

    // Close button functionality
    document.getElementById('close-popup').addEventListener('click', () => {
      iosInstallPopup.style.display = 'none';
    });
  }
});
// Handle beforeinstallprompt
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
    console.log('beforeinstallprompt event fired');
    e.preventDefault();  // Prevent the mini-infobar from appearing

    deferredPrompt = e;  // Save the event for later use
    const installButton = document.getElementById('installButton');
    const installButtonco = document.getElementById('install-button-container');

    installButtonco.style.display = 'block';

    installButton.addEventListener('click', () => {
      installButtonco.style.display = 'none';

        // Show the install prompt
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
            deferredPrompt = null;  // Reset the prompt variable
        });
    });
});