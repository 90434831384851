<template>
  <div>
    <icon-defs></icon-defs>

    <sticky-alert @click="visible = true"></sticky-alert>

 <sticky-alert2 @click="redirectToDriverSearch"></sticky-alert2>


    <site-navbar></site-navbar>

    <client-only>
      <alert-modal @store="alertRedirect" v-model:visible="visible" />
      <reservation-modal />
       <driver-reservation-modal />
      <ConfirmDialog group="confirmation">
        <template #message="slotProps">
          <div class="border-bottom-1 flex w-full flex-col items-center gap-5">
            <i style="font-size: 3rem" :class="slotProps.message.icon"></i>

            <p>{{ slotProps.message.message }}</p>
          </div>
        </template>
      </ConfirmDialog>
    </client-only>

    <slot></slot>

    <div id="install-button-container">
      <button id="installButton">Install App</button>
    </div>

    <div id="ios-install-popup" style="display: none;">
  <div id="ios-install-content">
    <p>To install this app on your iPhone, tap the <strong>Share</strong> icon and select <strong>"Add to Home Screen"</strong>.</p>
    <img src="../public/share-icon.png" alt="Share Icon" width="50">
    <button id="close-popup">Close</button>
  </div>
</div>

    <div  class="floating-call-container">
    <div v-if="isVisible" class="message-box">
      <span>Funargos publie vos trajets pour vous ! Contactez nous !</span>
      <button class="close-button" @click="toggleVisibility">✖</button>
    </div>
    <div class="call-icon" @click="makeCall">
      <!-- Use an icon, such as Font Awesome, or place an SVG/emoji here -->
      <span class="icon">🎧</span>
    </div>
  </div>
    <site-footer></site-footer>
  </div>
</template>

<script setup>
import Dialog from "primevue/dialog";

const router = useRouter();
const visible = ref(false);
const { $listen, $unListen } = useNuxtApp();
import { useAuthStore } from "~/store/auth";

const authStore = useAuthStore();

let interval = ref(null);
const isVisible = ref(true);

// Start interval when user is authenticated (authStore.isAuthenticated) for fetching notifications periodically
const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
};

// Function to handle the call action
const makeCall = () => {
  // Example: Open phone dialer or execute a different action
  window.location.href = "tel:+330458174065"; // Replace with actual phone number or action
};

watch(
  () => authStore.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      interval.value = setInterval(() => {
        authStore.fetchNotifications();
      }, 30000);
    } else {
      clearInterval(interval.value);
    }
  }, // watch options
  { immediate: true }
);

// on mounted listen to event

useHead({
  title: "Funargos",
  viewport: "width=device-width, initial-scale=1, maximum-scale=1",
  charset: "utf-8",
  link: [
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
  ],
    script: [
      {
        hid: 'service-worker',
        src: '/service-worker.js',
        type: 'text/javascript',
      },
    ],
  meta: [
    {
      name: "description",
      content: "Mise en relation des chauffeurs funéraires",
    },
  ],
});

const onOpenCreateAlert = () => {
  visible.value = true;
};
const showInstallButton = ref(false);
let deferredPrompt;
const handleResize = () => {
  console.log('Window resized');
};

onMounted(() => {

  $listen("open-create-alert", onOpenCreateAlert);

  // Request notification permission when the page loads or at an appropriate moment
  

});


const installApp = () => {
  if (deferredPrompt) {
    // Show the prompt to the user
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null; // Reset deferred prompt
      showInstallButton.value = false; // Hide the install button
    });
  }
};

onBeforeUnmount(() => {
  $unListen("open-create-alert", onOpenCreateAlert);
});

 const redirectToDriverSearch= () => {
      router.push({ path: '/votre-un-trajet' });
    };

const alertRedirect = () => {
  visible.value = false;
  router.push({
    path: "/dashboard/mes-alertes",
  });
};
</script>
<style scoped>
#ios-install-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#ios-install-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#close-popup {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

#installButton{
  /* display:none; */
}
#install-button-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: #1b2a4e;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display:none;
}
.dialog {
  padding: 0 !important;
}
.floating-call-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-box {
  background-color: #1b2a4e; /* Customize this color */
  color: #fff;
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.call-icon {
  background-color: #1b2a4e; /* Customize this color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.icon {
  font-size: 24px;
}
</style>
